import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Layout_page from "../components/layout_page"
import { Link } from "gatsby"
import { useState } from "react"

// needed to use font awsome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used


export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  const [filterName, setFilterName] = useState("")

  const pluggmaterial = frontmatter?.pluggmaterial_gallery?.filter(course => course.pluggmaterial_gallery_object.course_name.toLowerCase().includes(filterName)).map((item) => (
    <>
      <a href={item.pluggmaterial_gallery_object.course_url} className="block w-30 md:w-60 pr-20 md:px-0 py-4 text-white bg-neutral-800 rounded-lg hover:bg-neutral-700 shadow-2xl">
        <div className="mx-4">
          <p className="mt-2 font-semibold">{item.pluggmaterial_gallery_object.course_name}</p>
          <p>{item.pluggmaterial_gallery_object.comment}</p>
          <div className="mt-2">
            <p className="text-sm inline-block px-2 py-1 rounded-full bg-gray-700">Årskurs: {item.pluggmaterial_gallery_object.grade_year.map(year_item => (
              <span key={year_item.id}>{year_item} </span>
            ))}</p>
          </div>
        </div>
      </a>
    </>
  ))


  // if the page is marked as hidden in the markdwon file
  if (frontmatter.hidden) {

    return (
      <p className="">Denna sidan är dold. <Link to="/" className="text-blue-600">Gå till startsidan</Link></p>
    );

  } else if (frontmatter.special_formatting) {
    return (
      <Layout_page>

        <h1 className="mt-28 mb-2 text-3xl font-medium text-def_yellow_2">{frontmatter.title}</h1>

        <div className={frontmatter.special_formatting}>
          <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
        </div>

      </Layout_page>
    )

  } else {
    return (

      <Layout_page>

        <h1 className="mt-28 mb-2 text-3xl font-medium text-def_yellow_2">{frontmatter.title}</h1>


        {/* If there are gallery objects render the following html */}
        {frontmatter.gallery?.length > 0 &&
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-4">
            {frontmatter.gallery.map((item) => (
              <div className="w-60 text-white bg-gradient-to-b from-def_yellow_1 via-zinc-900 to-zinc-900 p-4 rounded-lg shadow-2xl">
                <img className="mx-auto w-40 h-40 object-cover rounded-full border-4 border-def_yellow_1" src={item.gallery_object.gallery_img} alt="" />
                <p className="mt-4 text-xl font-bold text-center">{item.gallery_object.role}</p>
                <p className="mt-4">{item.gallery_object.name}</p>
                <a className="block mt-2" href={`mailto:${item.gallery_object.mail}`}><FontAwesomeIcon icon={solid('envelope')} className="pr-2" />{item.gallery_object.mail}</a>
              </div>
            ))}
          </div>
        }

        {/* If there are merch objects render the following html */}
        {frontmatter.merch_gallery?.length > 0 &&
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-10 mx-2 mt-4 mb-16">
            {frontmatter.merch_gallery.map((item) => (
              <div className="w-30 md:w-60 py-4 text-white bg-neutral-800 rounded-lg shadow-2xl">
                <img className="mx-auto px-4 h-40 object-contain rounded-md" src={item.merch_gallery_object.gallery_img} alt="" />
                <div className="mx-4">
                  <p className="mt-4 font-semibold">{item.merch_gallery_object.product_name}</p>
                  <p className="mt-2">{item.merch_gallery_object.price} Kr</p>
                  <div className="mt-2">
                    <p className="text-sm inline-block px-2 py-1 rounded-full bg-gray-700">{item.merch_gallery_object.type}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        }


        {/* If there are pluggmaterial objects render the following html */}
        {frontmatter.pluggmaterial_gallery?.length > 0 &&
          <>
            <div className="flex mt-10 mb-10">
              <input className="rounded-full border-2 border-transparent" type="text" placeholder="Sök kursnamn..." value={filterName} onChange={(e) => setFilterName(e.target.value.toLowerCase())} />
              <button onClick={() => setFilterName("")}><FontAwesomeIcon className="inline-block text-white bg-neutral-500 hover:bg-neutral-400 text-xl ml-2 px-3 py-2 rounded-full" icon={solid('xmark')} /></button>
            </div>
            {pluggmaterial.length === 0 &&
              <p className="text-white text-center text-2xl font-semibold mt-10">Kunde inte hitta något om <br /><i>"{filterName}"</i></p>
            }
            <div className="grid grid-cols-1 grid-flow-dense md:grid-cols-2 lg:grid-cols-3 gap-7 md:gap-10 mx-2 mt-4 mb-16">
              {pluggmaterial}
            </div>


          </>
        }



        <article className="prose prose-invert mx-8">
          <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
        </article>

      </Layout_page>


    )
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        hidden
        special_formatting
        gallery {
          gallery_object {
            gallery_img
            mail
            name
            role
          }
        }
        merch_gallery {
          merch_gallery_object {
            gallery_img
            product_name
            price
            type
          }
        }
        pluggmaterial_gallery {
          pluggmaterial_gallery_object {
            comment
            course_name
            course_url
            grade_year
          }
        }
      }
    }
  }
`
/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({ data }) => <Seo title={data.markdownRemark.frontmatter.title} />